var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.loading
    }
  }, [_c('b-card', {
    attrs: {
      "title": "Daftar Barang yang belum ditempatkan ke Penyimpanan Toko"
    }
  }, [_c('b-row', [_c('b-col', {
    staticClass: "my-1",
    attrs: {
      "md": "2",
      "sm": "4"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label-size": "sm",
      "label": _vm.$t('Per page'),
      "label-for": "perPage"
    }
  }, [_c('b-form-select', {
    attrs: {
      "id": "perPageSelect",
      "size": "sm",
      "options": _vm.pageOptions
    },
    model: {
      value: _vm.perPage,
      callback: function ($$v) {
        _vm.perPage = $$v;
      },
      expression: "perPage"
    }
  })], 1)], 1), _c('b-col', {
    staticClass: "my-1",
    attrs: {
      "md": "10",
      "sm": "8"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label": _vm.$t('Filter'),
      "label-size": "sm",
      "label-for": "filterInput"
    }
  }, [_c('b-input-group', {
    attrs: {
      "size": "sm"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "filterInput",
      "type": "search",
      "placeholder": "Tekan enter untuk cari"
    },
    on: {
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        $event.preventDefault();
        return _vm.getsementara();
      }
    },
    model: {
      value: _vm.filter,
      callback: function ($$v) {
        _vm.filter = $$v;
      },
      expression: "filter"
    }
  }), _c('b-input-group-append', [_c('b-button', {
    attrs: {
      "disabled": !_vm.filter
    },
    on: {
      "click": function ($event) {
        _vm.filter = '';
        _vm.getsementara();
      }
    }
  }, [_vm._v(" Clear ")])], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-table', {
    attrs: {
      "small": "",
      "striped": "",
      "hover": "",
      "responsive": "",
      "per-page": _vm.perPage,
      "items": _vm.pengirimanBarangs,
      "fields": _vm.fieldsasli
    },
    scopedSlots: _vm._u([{
      key: "cell(no)",
      fn: function (row) {
        return [_c('strong', {
          staticClass: "text-center"
        }, [_vm._v(" " + _vm._s(row.index + 1) + " ")])];
      }
    }, {
      key: "cell(selesai)",
      fn: function (data) {
        return [_c('b-badge', {
          attrs: {
            "variant": "primary"
          }
        }, [_vm._v(" " + _vm._s(data.item.status_ket ? data.item.status_ket.toUpperCase() : "-") + " ")])];
      }
    }, {
      key: "cell(po)",
      fn: function (_ref) {
        var item = _ref.item;
        return [_vm._v(" " + _vm._s(item.po ? item.po.no : "po kosong") + " ")];
      }
    }, {
      key: "cell(tanggal_pengiriman)",
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_vm._v(" " + _vm._s(_vm.humanDate(item.tanggal)) + " ")];
      }
    }, {
      key: "cell(tanggal_invoice)",
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_vm._v(" " + _vm._s(_vm.humanDate(item.tanggal_invoice)) + " ")];
      }
    }, {
      key: "cell(actions)",
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_c('section', {
          staticClass: "flex item-center"
        }, [_c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.right",
            value: 'Tempatkan Barang ke Penyimpanan',
            expression: "'Tempatkan Barang ke Penyimpanan'",
            modifiers: {
              "hover": true,
              "right": true
            }
          }],
          staticClass: "mr-1",
          attrs: {
            "size": "sm",
            "variant": "outline-info"
          },
          on: {
            "click": function ($event) {
              return _vm.tempatkan(item);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "LogInIcon"
          }
        }), _vm._v(" Tempatkan Barang ke Penyimpanan ")], 1)], 1)];
      }
    }, {
      key: "cell(actionsCabang)",
      fn: function (_ref5) {
        var item = _ref5.item;
        return [_c('section', {
          staticClass: "flex item-center"
        }, [item.jumlah <= 0 ? _c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.right",
            value: 'Sudah di tempatkan',
            expression: "'Sudah di tempatkan'",
            modifiers: {
              "hover": true,
              "right": true
            }
          }],
          staticClass: "mr-1",
          attrs: {
            "disabled": "",
            "size": "sm",
            "variant": "outline-success"
          },
          on: {
            "click": function ($event) {
              return _vm.tempatkan(item);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "LogInIcon"
          }
        }), _vm._v(" Sudah di tempatkan ")], 1) : _vm._e(), item.jumlah > 0 ? _c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.right",
            value: 'Tempatkan Barang ke Penyimpanan',
            expression: "'Tempatkan Barang ke Penyimpanan'",
            modifiers: {
              "hover": true,
              "right": true
            }
          }],
          staticClass: "mr-1",
          attrs: {
            "size": "sm",
            "variant": "info"
          },
          on: {
            "click": function ($event) {
              return _vm.tempatkan(item);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "LogInIcon"
          }
        }), _vm._v(" Tempatkan Barang ke Penyimpanan ")], 1) : _vm._e()], 1)];
      }
    }, {
      key: "row-details",
      fn: function (row) {
        return [_c('b-card', [_c('ul', _vm._l(row.item, function (value, key) {
          return _c('li', {
            key: key
          }, [_vm._v(" " + _vm._s(key) + ": " + _vm._s(value) + " ")]);
        }), 0)])];
      }
    }])
  })], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-pagination', {
    staticClass: "my-0",
    attrs: {
      "total-rows": _vm.totalRows,
      "per-page": _vm.perPage,
      "align": "center",
      "size": "sm"
    },
    model: {
      value: _vm.currentPage,
      callback: function ($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  })], 1)], 1)], 1), _vm.allowCreate() || _vm.allowUpdate() ? _c('b-modal', {
    attrs: {
      "id": "modal-",
      "ok-title": "Tutup",
      "ok-variant": "secondary",
      "ok-only": "",
      "centered": "",
      "title": 'Penempatan Barang : ' + _vm.namabarang + ' - ' + _vm.varianbarang,
      "size": "xl"
    },
    model: {
      value: _vm.showModal,
      callback: function ($$v) {
        _vm.showModal = $$v;
      },
      expression: "showModal"
    }
  }, [_c('b-row', [_c('b-col', [_c('b-form-group', {
    attrs: {
      "label": "Buat Penyimpanan Baru untuk barang ini?"
    }
  }, [_c('b-form-radio', {
    attrs: {
      "name": "some-radios",
      "value": "2"
    },
    model: {
      value: _vm.mode,
      callback: function ($$v) {
        _vm.mode = $$v;
      },
      expression: "mode"
    }
  }, [_vm._v("Buat Penyimpanan baru")]), _c('b-form-radio', {
    attrs: {
      "name": "some-radios",
      "value": "1"
    },
    model: {
      value: _vm.mode,
      callback: function ($$v) {
        _vm.mode = $$v;
      },
      expression: "mode"
    }
  }, [_vm._v("Pilih penyimpanan yang sudah ada")])], 1)], 1)], 1), _c('b-row', [_vm.mode == 2 ? _c('b-col', {
    attrs: {
      "sm": "6",
      "md": "6",
      "lg": "6",
      "xl": "6"
    }
  }, [_c('b-card', {
    attrs: {
      "title": "Buat Penyimpanan Baru",
      "border-variant": "primary"
    }
  }, [_c('validation-observer', {
    ref: "form2"
  }, [_c('form', [_c('b-row', [_c('b-col', {
    attrs: {
      "sm": "4",
      "md": "4",
      "lg": "4",
      "xl": "4"
    }
  }, [_c('b-form-group', [_c('validation-provider', {
    attrs: {
      "name": "blok",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref6) {
        var errors = _ref6.errors;
        return [_c('label', {
          attrs: {
            "for": ""
          }
        }, [_vm._v("Pilih Blok")]), _c('b-form-select', {
          attrs: {
            "options": _vm.blok_id,
            "required": !_vm.form.blok_id,
            "reduce": function (option) {
              return option.value;
            },
            "label": "text"
          },
          model: {
            value: _vm.form.blok_id,
            callback: function ($$v) {
              _vm.$set(_vm.form, "blok_id", $$v);
            },
            expression: "form.blok_id"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 415995372)
  })], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "4",
      "md": "4",
      "lg": "4",
      "xl": "4"
    }
  }, [_c('b-form-group', [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v("Pilih Pallet")]), _c('b-form-select', {
    attrs: {
      "options": _vm.palet_id,
      "required": !_vm.form.palet_id,
      "reduce": function (option) {
        return option.value;
      },
      "label": "text"
    },
    model: {
      value: _vm.form.palet_id,
      callback: function ($$v) {
        _vm.$set(_vm.form, "palet_id", $$v);
      },
      expression: "form.palet_id"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "4",
      "md": "4",
      "lg": "4",
      "xl": "4"
    }
  }, [_c('b-form-group', [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v("Pilih Rak (optional)")]), _c('b-form-select', {
    staticClass: "mb-2",
    attrs: {
      "options": _vm.rak_id,
      "reduce": function (option) {
        return option.value;
      },
      "label": "text"
    },
    model: {
      value: _vm.form.rak_id,
      callback: function ($$v) {
        _vm.$set(_vm.form, "rak_id", $$v);
      },
      expression: "form.rak_id"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "4",
      "md": "4",
      "lg": "4",
      "xl": "4"
    }
  }, [_c('b-form-group', [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v("Pilih Laci (optional)")]), _c('b-form-select', {
    staticClass: "mb-2",
    attrs: {
      "options": _vm.laci_id,
      "reduce": function (option) {
        return option.value;
      },
      "label": "text"
    },
    model: {
      value: _vm.form.laci_id,
      callback: function ($$v) {
        _vm.$set(_vm.form, "laci_id", $$v);
      },
      expression: "form.laci_id"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "4",
      "md": "4",
      "lg": "4",
      "xl": "4"
    }
  }, [_c('b-form-group', [_c('b-button', {
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.addBarang($event);
      }
    }
  }, [_vm._v("Tambah")])], 1)], 1)], 1)], 1)])], 1)], 1) : _vm._e(), _vm.mode == 2 ? _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('b-card', {
    attrs: {
      "title": "Daftar Penyimpanan Baru yang akan disimmpan",
      "border-variant": "primary"
    }
  }, [_c('b-table', {
    attrs: {
      "fields": _vm.fieldsAddedItems,
      "bordered": "",
      "striped": "",
      "responsive": "",
      "items": _vm.addedItems
    },
    scopedSlots: _vm._u([{
      key: "cell(no)",
      fn: function (row) {
        return [_vm._v(" " + _vm._s(row.index + 1) + " ")];
      }
    }, {
      key: "cell(lokasi)",
      fn: function (_ref7) {
        var item = _ref7.item;
        return [_c('strong', [_vm._v(" " + _vm._s(item.lokasi) + " ")])];
      }
    }, {
      key: "cell(action)",
      fn: function (row) {
        return [_c('b-link', {
          staticClass: "text-danger",
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.addedItems.splice(row.index, 1);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "size": "24",
            "icon": "XCircleIcon"
          }
        })], 1)];
      }
    }], null, false, 1822751536)
  }), _c('div', {
    staticClass: "d-flex justify-content-end"
  }, [_c('b-button', {
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.submitPenyimpanan($event);
      }
    }
  }, [_vm._v(" Simpan ")])], 1)], 1)], 1) : _vm._e(), _vm.mode == 1 ? _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "8",
      "lg": "8",
      "xl": "8"
    }
  }, [_vm.items.length > 0 ? _c('b-card', {
    attrs: {
      "border-variant": "primary",
      "title": "Pilih Penyimpanan Barang"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "sm": "12",
      "md": "12",
      "lg": "12"
    }
  }, [_vm.loaded ? _c('b-table', {
    attrs: {
      "striped": "",
      "small": "",
      "hover": "",
      "responsive": "",
      "items": _vm.items,
      "fields": _vm.fieldsPenyimpananBarangs
    },
    scopedSlots: _vm._u([{
      key: "cell(penyimpanan)",
      fn: function (data) {
        return [_c('b-form-checkbox', {
          attrs: {
            "value": data.item.id
          },
          model: {
            value: _vm.pilihbarang,
            callback: function ($$v) {
              _vm.pilihbarang = $$v;
            },
            expression: "pilihbarang"
          }
        })];
      }
    }, {
      key: "cell(no)",
      fn: function (row) {
        return [_c('strong', {
          staticClass: "text-center"
        }, [_vm._v(" " + _vm._s(row.index + 1) + " ")])];
      }
    }, {
      key: "cell(kode)",
      fn: function (_ref8) {
        var item = _ref8.item;
        return [_vm._v(" " + _vm._s(item.barang ? item.barang.kode : item.barang_id) + " ")];
      }
    }, {
      key: "cell(nama)",
      fn: function (_ref9) {
        var item = _ref9.item;
        return [_vm._v(" " + _vm._s(item.barang ? item.barang.nama : item.barang_id) + " ")];
      }
    }, {
      key: "cell(blok)",
      fn: function (_ref10) {
        var item = _ref10.item;
        return [_vm._v(" " + _vm._s(item.blok ? item.blok.blok : item.blok_id) + " ")];
      }
    }, {
      key: "cell(palet)",
      fn: function (_ref11) {
        var item = _ref11.item;
        return [_vm._v(" " + _vm._s(item.palet ? item.palet.palet : item.palet_id) + " ")];
      }
    }, {
      key: "cell(rak)",
      fn: function (_ref12) {
        var item = _ref12.item;
        return [_vm._v(" " + _vm._s(item.rak ? item.rak.rak : item.rak_id) + " ")];
      }
    }, {
      key: "cell(laci)",
      fn: function (_ref13) {
        var item = _ref13.item;
        return [_vm._v(" " + _vm._s(item.laci ? item.laci.laci : item.laci_id) + " ")];
      }
    }, {
      key: "cell(stok)",
      fn: function (_ref14) {
        var item = _ref14.item;
        return [_vm._v(" " + _vm._s(item.stok ? item.stok : item.jumlah) + " ")];
      }
    }, {
      key: "cell(satuan)",
      fn: function (_ref15) {
        var item = _ref15.item;
        return [_vm._v(" " + _vm._s(item.barang ? item.barang.satuan.satuan : item.barang_id) + " ")];
      }
    }], null, false, 599057878)
  }) : _vm._e()], 1)], 1)], 1) : _vm._e(), _vm.items.length == 0 ? _c('b-card', {
    staticClass: "text-center",
    attrs: {
      "border-variant": "primary"
    }
  }, [_c('div', {
    staticClass: "text-danger"
  }, [_vm._v(" Barang tersebut belum diatur penyimpanannya ")])]) : _vm._e()], 1) : _vm._e(), _vm.mode == 1 ? _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "4",
      "lg": "4",
      "xl": "4"
    }
  }, [_c('b-card', {
    attrs: {
      "border-variant": "primary",
      "title": "Kuantitas"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0"
  }, [_c('b-input-group', {
    attrs: {
      "size": "lg"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "Kuantitas",
      "type": "search",
      "placeholder": ""
    },
    model: {
      value: _vm.jumlah,
      callback: function ($$v) {
        _vm.jumlah = $$v;
      },
      expression: "jumlah"
    }
  }), _c('b-input-group-append', [_c('b-button', {
    attrs: {
      "variant": "success"
    },
    on: {
      "click": function ($event) {
        return _vm.simpan();
      }
    }
  }, [_vm._v(" Simpan ")])], 1)], 1)], 1)], 1)], 1) : _vm._e()], 1)], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }